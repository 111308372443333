import { ReactElement } from "react";
import { Link } from "react-router-dom";


export function NotFoundPage() : ReactElement {

    return ( 
      
        <main role="main" className="container">
            <h1>Pagina niet gevonden</h1>
            <p>De pagina die u probeerde op te vragen is niet gevonden</p>
            <p>Naar de home pagina: <Link to="/">Home</Link></p>
        </main>
    );
}