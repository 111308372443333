import { ReactElement } from "react";
import moment from 'moment';

import { useParams } from "react-router-dom";
import { ScanResultComponent } from "../../components/scanresult/scanresult";
import { DocQr, IDocQr } from "../../domain/docqr.domain";
import { DocQrService } from "../../domain/services/docqr.service";

export function ResultPage() : ReactElement {

    let { werkmaatschappij, projectcode, documentnummer, revisie, revisiedatum } = useParams() as { werkmaatschappij:string, projectcode:string, documentnummer:string, revisie:string, revisiedatum:string};

    let docQr: IDocQr;
    docQr = new DocQr(new DocQrService());

    docQr.loadScanResult(werkmaatschappij, projectcode, documentnummer, revisie, revisiedatum);

    return ( 
      
        <main role="main" className="container-fluid">
            <h3>Gescand document</h3>
            <dl className="row">
                <dt className="col-sm-4 col-md-3 col-lg-2">Documentnummer</dt>
                <dd className="col-sm-8 col-md-9 col-lg-10">{documentnummer}</dd>
                <dt className="col-sm-4 col-md-3 col-lg-2">Revisie</dt>
                <dd className="col-sm-8 col-md-9 col-lg-10">{revisie}</dd>
                <dt className="col-sm-4 col-md-3 col-lg-2">Revisiedatum</dt>
                <dd className="col-sm-8 col-md-9 col-lg-10">{moment(revisiedatum, 'YYYYMMDD').format('DD-MM-YYYY')}</dd>
            </dl>
            
            <h3>Resultaat van controle</h3>

            <ScanResultComponent docQr={docQr} />
        </main>
    );
}