import { ReactElement } from "react";

import './footer.scss'
import { Container } from "react-bootstrap";

export function Footer() : ReactElement {

    return ( 
        <footer >
            <Container>
                <p className="text-center">© {(new Date().getFullYear())} | VolkerWessels Infrastructuur</p>
            </Container>
        </footer>
    );
}