import { ReactElement } from "react";

export function HomePage() : ReactElement {

    return ( 
      
        <main role="main" className="container">
            <h1 >Home</h1>
            <p className="lead">Deze website wordt gebruikt om het resultaat van een DocQr qr code scan weer te geven.</p>
            <p>Is het document de laaste versie of is er inmiddels een nieuwere versie beschikbaar?</p>
        </main>
    );
}