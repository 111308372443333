
import { BehaviorSubject, Observable } from "rxjs"
import { IDocQrService } from "./services/docqr.service";

export interface IDocQr {

    scanResultObservable: Observable<ScanResult>;
    scanResultLoadingObservable: Observable<boolean>;

    loadScanResult(werkmaatschappij:string, projectcode:string, documentnummer:string, revisie:string, revisiedatum:string) : Promise<void>;
}

export class ScanResult {
    isSuccess: boolean = false;
    errorMessage: string = '';
    hasNumericAndTextRevision: boolean = false;
    isExpired: boolean = false;
    isDocumentDeleted: boolean = false;
    isRevisionDeleted: boolean = false;
    isLatestVersion: boolean = false;
    isNotLatestVersion: boolean = false;
    isLatestVersionNotPublished: boolean = false;
    isLatestVersionSharedHigher: boolean = false;
    documents: Document[] = [];
}

export class Document {
    documentName: string = '';
    documentNumber: string = '';
    documentUrl: string = '';
    listName: string = '';
    revision: string = '';
    revisionDate: Date = new Date(0);
    status: string = '';
    processStatus: string = '';
}

export class DocQr implements IDocQr {
    
    private _docQrService: IDocQrService;

    constructor(docQrService: IDocQrService) {
      this._docQrService = docQrService;
    }

    public scanResultObservable: BehaviorSubject<ScanResult> = new BehaviorSubject<ScanResult>(new ScanResult());
    public scanResultLoadingObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public async loadScanResult(werkmaatschappij:string, projectcode:string, documentnummer:string, revisie:string, revisiedatum:string) : Promise<void> {
        
        this.scanResultLoadingObservable.next(true);

        var scanResult = await this._docQrService.getDocQrResult(werkmaatschappij, projectcode, documentnummer, revisie, revisiedatum);
    
        this.scanResultObservable.next(scanResult);
        this.scanResultLoadingObservable.next(false);
    }
}