import * as React from "react";

import { ReactElement, useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import moment from 'moment';


import './scanresult.scss'
import { IDocQr, ScanResult } from "../../domain/docqr.domain";


export interface IScanResultProps {
  docQr  :IDocQr
}

export function ScanResultComponent( { docQr } :IScanResultProps) : ReactElement {

  const [loading, setLoading] = useState<boolean>(false);
  const [scanResult, setScanResult] = useState<ScanResult>();

  const onScanResultLoadingChanged = (loading: boolean) : void => {
    setLoading(loading);
  };

  const onScanResultChanged = (scanResult: ScanResult) : void => {
    setScanResult(scanResult);
  };

  useEffect(() => {
    const loadingSubscription = docQr.scanResultLoadingObservable.subscribe(onScanResultLoadingChanged);
    const resultsSubscription = docQr.scanResultObservable.subscribe(onScanResultChanged);

    return () => {
      loadingSubscription.unsubscribe();
      resultsSubscription.unsubscribe();
    }
  }, [docQr.scanResultLoadingObservable, docQr.scanResultObservable]);

    return (
      <>
        { loading && 
          <div className="d-flex flex-row">
            <div className="p-2"><ClipLoader loading={loading} color="#497cab" speedMultiplier={0.8} /></div>
            <div className="p-2">de scanresultaten worden geladen ...</div>
          </div>
        }

        { !loading && scanResult && scanResult.isSuccess && 
        <>
            { scanResult.hasNumericAndTextRevision &&
              <div className="alert alert-info" role="alert">De hoogste revisie van het document kan niet worden bepaald. Er worden tekstuele en numerieke revisies gebruikt. Bepaal zelf de hoogste revisie in de onderstaande tabel.</div> 
            }

            { scanResult.isLatestVersion &&
              <div className="alert alert-success" role="alert">Dit is de hoogste revisie van het document.</div> 
            }

            { scanResult.isLatestVersionNotPublished && 
              <div className="alert alert-danger" role="alert">Dit is de hoogste revisie van het document. Let op! Het document is nog niet vrijgegeven.</div>
            }

            { scanResult.isLatestVersionSharedHigher && 
              <div className="alert alert-success" role="alert">Dit is de hoogste vrijgegeven revisie van het document. Let op! Er is een hogere revisie aanwezig in gedeeld.</div>
            }

            { scanResult.isNotLatestVersion && scanResult.documents.length > 0 &&      
              <div className="alert alert-danger" role="alert">Dit is niet de hoogste revisie van het document.</div>
            }

            { scanResult.isExpired &&
              <div className="alert alert-danger" role="alert">Deze revisie van het document is vervallen.</div> 
            }

            { scanResult.isRevisionDeleted &&
              <div className="alert alert-danger" role="alert">Deze revisie van het document is verwijderd.</div> 
            }

            { scanResult.isDocumentDeleted &&
              <div className="alert alert-danger" role="alert">Dit document is verwijderd.</div> 
            }

            { scanResult.documents.length <= 0 &&           
              <div className="alert alert-danger" role="alert">Er zijn geen documenten gevonden.</div>
            }
            
            { scanResult.documents.length > 0 && 
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Document</th>
                      <th>Revisie</th>
                      <th>Revisiedatum</th>
                      <th>Bibliotheek</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      scanResult.documents.map((document, i) => {     
                      
                        return (
                          <tr className={document.status === "Vervallen" || document.status === "Verwijderd" ? "table-danger" : ""}>
                            <td>
                            { document.status !== "Verwijderd" && <a target="_blank" rel="noopener noreferrer" href={document.documentUrl}>{document.documentName}</a>  }
                            { document.status === "Verwijderd" && document.documentName }
                            </td>
                            <td>{document.revision}</td>
                            <td>{moment(document.revisionDate).format('DD-MM-YYYY')}</td>
                            <td>{document.listName}</td>
                            <td>{document.status}</td>
                          </tr>) 
                      })
                    }
                        
                    </tbody>
                </table>     
              </div>
            }          
        </> 
       }

       { !loading && scanResult && !scanResult.isSuccess &&
          <div className="alert alert-danger" role="alert">Er is een fout opgetreden bij het laden van het scanresultaat: {scanResult.errorMessage}</div>
       }

      </>
    );
}