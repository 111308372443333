import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import { HomePage } from "../pages/homepage/homePage";
import { ResultPage } from "../pages/resultpage/resultPage";
import { NotFoundPage } from "../pages/notfoundpage/notfoundPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { path: "", element: <HomePage />},
            { path: ":werkmaatschappij/:projectcode/:documentnummer/:revisie/:revisiedatum", element: <ResultPage />},
            { path: "*", element: <NotFoundPage />}
        ]
    }
])