import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'

import { Nav } from './components/nav/nav';
import { Outlet } from 'react-router'
import { Footer } from './components/footer/footer';

import './app.scss';

function App() {
  return (
    <>
      <Nav />
      <Outlet />
      <Footer />
    </>
    
  );
}

export default App;
