import { ScanResult } from '../docqr.domain';

export interface IDocQrService
{
  getDocQrResult(werkmaatschappij:string, projectcode:string, documentnummer:string, revisie:string, revisiedatum:string) : Promise<ScanResult>;
}

export class DocQrService implements IDocQrService
{

    public async getDocQrResult(werkmaatschappij:string, projectcode:string, documentnummer:string, revisie:string, revisiedatum:string) : Promise<ScanResult> {
    
        let url = `${process.env.REACT_APP_DOCQR_API_BASEURL}/api/docqr/${werkmaatschappij}/${projectcode}/${documentnummer}/${revisie}/${revisiedatum}`;

        try {

            var response = await fetch(url);
            
            if(response.ok) {

                let result: ScanResult = await response.json();
                result.isSuccess = true;

                return result;
            }
            else {
                const errorMessage: string = await response.text();

                throw new Error(errorMessage || response.statusText);
            } 
        } catch (error) {

            let result = new ScanResult();

            result.isSuccess = false;

            if (error instanceof Error) {
                result.errorMessage = error.message;
            }
            
            return result;
        }
    }

}