import * as React from "react";

import logo from '../../assets/images/logo.svg';
import { ReactElement } from "react";
import { Navbar, Container } from 'react-bootstrap';

import './nav.scss'


export function Nav() : ReactElement {

    return (
        <header>
        <Navbar fixed="top" className="border-bottom bg-white">
            <Container fluid>
                <Navbar.Brand href="#home"> 
                    <img src={logo} alt="Logo" /> 
                </Navbar.Brand> 
                <Navbar.Collapse className="justify-content-center">
                    <Navbar.Text >
                        DocQr
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </header>
    );
}